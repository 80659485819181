import { useYooptaEditor, useYooptaTools } from '@yoopta/editor';
import cx from 'classnames';
import { CodeIcon, ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import { useState } from 'react';
import { useFloating, offset, flip, shift, inline, autoUpdate, FloatingPortal } from '@floating-ui/react';
import s from './EditorToolbar.module.css';
import { buildActionMenuRenderProps } from '@yoopta/action-menu-list';
import { HighlightColor } from './HighlightColor'; // Import HighlightColor component

const DEFAULT_MODALS = { link: false, highlight: false, actionMenu: false };

const EditorToolbar = (props) => {
  const [modals, setModals] = useState(DEFAULT_MODALS);

  // positioning for action menu tool
  const { refs: actionMenuRefs, floatingStyles: actionMenuStyles } = useFloating({
    placement: 'bottom-start',
    open: modals.actionMenu,
    onOpenChange: (open) => onChangeModal('actionMenu', open),
    middleware: [inline(), flip(), shift(), offset(10)],
    whileElementsMounted: autoUpdate,
  });

  const { refs: highlightPickerRefs, floatingStyles: highlightPickerStyles } = useFloating({
    placement: 'top-end',
    open: modals.highlight,
    onOpenChange: (open) => onChangeModal('highlight', open),
    middleware: [inline(), flip(), shift(), offset(10)],
    whileElementsMounted: autoUpdate,
  });

  const onChangeModal = (modal, value) => {
    setModals((prevModals) => ({ ...prevModals, [modal]: value }));
  };

  const { activeBlock } = props;
  const editor = useYooptaEditor();
  const tools = useYooptaTools();

  const blockLabel = activeBlock?.options?.display?.title || activeBlock?.type || '';
  const ActionMenu = tools.ActionMenu;

  const onCloseActionMenu = () => onChangeModal('actionMenu', false);

  const actionMenuRenderProps = buildActionMenuRenderProps({
    editor,
    onClose: onCloseActionMenu,
    view: 'small',
    mode: 'toggle',
  });

  const handleAskAiClick = async (editor) => {
    if (!editor.selection) {
      alert('Please select some text to ask AI.');
      return;
    }
    console.log(editor)

    const selectedBlock = editor.getBlock({ at: editor.selection });
    // console.log(selectedBlock)
    const formattedString = formatBlockToString(selectedBlock.value[0]);
    // console.log(formattedString)

    if (!formattedString) {
      alert('Please select some text to ask AI.');
      return;
    }

    try {
      editor.emit('callAIChatBot', { id: selectedBlock.id, selection: editor.selection, question: formattedString});
    } catch (error) {
      console.error('Error asking AI:', error);
    }
  };

  const highlightColors = editor.formats.highlight?.getValue();
  const getHighlightTriggerStyle = () => ({
    color: highlightColors?.color,
    backgroundColor: highlightColors?.backgroundColor,
    backgroundImage: highlightColors?.backgroundImage,
    WebkitTextFillColor: highlightColors?.webkitTextFillColor,
  });

  function formatBlockToString(block) {
    return block.children.map(child => child.text).join('');
  }

  return (
    <div>
      <div className={s.toolbar}>
        <div className={s.group}>
          <button type="button" className={s.item} onClick={() => handleAskAiClick(editor)}>
            <span className={s.askGPT}>Ask AI</span>
          </button>
        </div>
        <div className={s.group}>
          <button
            type="button"
            className={s.item}
            ref={actionMenuRefs.setReference}
            onClick={() => onChangeModal('actionMenu', !modals.actionMenu)}
          >
            <span className={s.block}>
              {blockLabel} <ChevronDownIcon size={12} strokeWidth={2} color="rgba(55, 53, 47, 0.35)" />
            </span>
            {modals.actionMenu && ActionMenu && (
              <FloatingPortal
                id="yoo-custom-toolbar-action-menu-list-portal"
                root={document.getElementById('yoopta-editor')}
              >
                <div style={actionMenuStyles} ref={actionMenuRefs.setFloating} onClick={(e) => e.stopPropagation()}>
                  <ActionMenu {...actionMenuRenderProps} />
                </div>
              </FloatingPortal>
            )}
          </button>
        </div>
        <div className={s.group}>
          <button
            type="button"
            onClick={() => editor.formats.bold.toggle()}
            className={cx(s.item, { [s.active]: editor.formats.bold.isActive() })}
          >
            <span className={s.bold}>B</span>
          </button>
          <button
            type="button"
            onClick={() => editor.formats.italic.toggle()}
            className={cx(s.item, { [s.active]: editor.formats.italic.isActive() })}
          >
            <span className={s.italic}>i</span>
          </button>
          <button
            type="button"
            onClick={() => editor.formats.underline.toggle()}
            className={cx(s.item, { [s.active]: editor.formats.underline.isActive() })}
          >
            <span className={s.underline}>U</span>
          </button>
          <button
            type="button"
            onClick={() => editor.formats.strike.toggle()}
            className={cx(s.item, { [s.active]: editor.formats.strike.isActive() })}
          >
            <span className={s.strikethrough}>S</span>
          </button>
          <button
            type="button"
            onClick={() => editor.formats.code.toggle()}
            className={cx(s.item, { [s.active]: editor.formats.code.isActive() })}
          >
            <span className={s.code}>
              <CodeIcon size={15} strokeWidth={1.5} />
            </span>
          </button>
          <button
            type="button"
            className={cx(s.item, { [s.active]: modals.highlight })}
            ref={highlightPickerRefs.setReference}
            onClick={() => onChangeModal('highlight', !modals.highlight)}
            style={getHighlightTriggerStyle()}
          >
            <span className={s.highlight}>A</span>
            {modals.highlight ? <ChevronUpIcon size={12} /> : <ChevronDownIcon size={12} />}
          </button>
          {modals.highlight && (
            <FloatingPortal id="yoo-highlight-color-portal" root={document.getElementById('yoopta-editor')}>
              <div style={highlightPickerStyles} ref={highlightPickerRefs.setFloating}>
                <HighlightColor
                  editor={editor}
                  highlightColors={highlightColors}
                  onClose={() => onChangeModal('highlight', false)}
                  refs={highlightPickerRefs}
                  floatingStyles={highlightPickerStyles}
                />
              </div>
            </FloatingPortal>
          )}
        </div>
      </div>
    </div>
  );
};

export { EditorToolbar };