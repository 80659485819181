// export const WITH_CUSTOM_TOOLBAR_INIT_VALUE = {
//     '445428d3-3671-4a13-8a0d-030e4f8c545e': {
//       id: '445428d3-3671-4a13-8a0d-030e4f8c545e',
//       value: [
//         {
//           id: '65a372e6-459b-4621-ab2b-12154cbed0fd',
//           type: 'heading-two',
//           children: [
//             {
//               text: 'BioTech 助手測試',
//             },
//           ],
//           props: {
//             nodeType: 'block',
//           },
//         },
//       ],
//       type: 'HeadingTwo',
//       meta: {
//         order: 0,
//         depth: 0,
//       },
//     },
//     '95b9f8ca-0985-4345-ade6-033985cff3c5': {
//       id: '95b9f8ca-0985-4345-ade6-033985cff3c5',
//       value: [
//         {
//           id: '86ec9c0e-e3ca-4646-b89b-0e8a9f2b0330',
//           type: 'paragraph',
//           children: [
//             {
//               text: "台灣各個公司臨床實驗的第幾期個數 pivot",
//             },
//           ],
//           props: {
//             nodeType: 'block',
//           },
//         },
//       ],
//       type: 'Paragraph',
//       meta: {
//         order: 1,
//         depth: 0,
//       },
//     },
//     'dc290180-21b3-459a-bb25-c39cf01651e4': {
//       id: 'dc290180-21b3-459a-bb25-c39cf01651e4',
//       value: [
//         {
//           id: 'aa43aa5e-9b25-453d-89d2-04b1c32dbcc3',
//           type: 'paragraph',
//           children: [
//             {
//               text: "生技公司數量與 Founded Year 的關係，用圓餅圖表示",
//             },
//           ],
//           props: {
//             nodeType: 'block',
//           },
//         },
//       ],
//       type: 'Paragraph',
//       meta: {
//         order: 2,
//         depth: 0,
//       },
//     },
//     '10d4d077-56fb-4368-923f-e675ecfa8a30': {
//       id: '10d4d077-56fb-4368-923f-e675ecfa8a30',
//       value: [
//         {
//           id: '2e8924e8-3383-4bd0-8999-043fbe0a7438',
//           type: 'paragraph',
//           children: [
//             {
//               text: '台灣臨床實驗數目與二期試驗的關係 Pivot',
//             },
//           ],
//           props: {
//             nodeType: 'block',
//           },
//         },
//       ],
//       type: 'Paragraph',
//       meta: {
//         order: 3,
//         depth: 0,
//       },
//     },
//   };

export const WITH_CUSTOM_TOOLBAR_INIT_VALUE = {
  '95b9f8ca-0985-4345-ade6-033985cff3c5': {
    id: '95b9f8ca-0985-4345-ade6-033985cff3c5',
    value: [
      {
        id: '86ec9c0e-e3ca-4646-b89b-0e8a9f2b0330',
        type: 'paragraph',
        children: [
          {
            text: "Write something here, or type / to select a block type :)",
          },
        ],
        props: {
          nodeType: 'block',
        },
      },
    ],
    type: 'Paragraph',
    meta: {
      order: 1,
      depth: 0,
    },
  },
};