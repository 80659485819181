import { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MoreHorizontal, Trash2 } from 'lucide-react';
import toast from 'react-hot-toast';

import CustomScrollbar from './customScrollbar';
import NewProjectService from '../service/project-service';

import logo from "../assets/images/sideBar/logo.png";
import addBtn from "../assets/images/sideBar/addBtn.png";

import style from "./sideBar.module.scss";

export default function SideBar({ projectList, setProjectList, getAllProjects, currentId, getProjectDetails }) {
    const [activeItem, setActiveItem] = useState(null);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [showMenu, setShowMenu] = useState(false);
    const scrollContainerRef = useRef(null);
    const Navigate = useNavigate();
    const { id } = useParams();

    const scrollUp = () => {
        const container = scrollContainerRef.current;
        if (container) {
            container.scrollBy({ top: -100, behavior: 'smooth' });
        }
    };

    const scrollDown = () => {
        const container = scrollContainerRef.current;
        if (container) {
            container.scrollBy({ top: 100, behavior: 'smooth' });
        }
    };

    const createNewProject = async () => {
        try {
            const res = await NewProjectService.create({
              "title" : "Title",
              "content" : "<body id='yoopta-clipboard' data-editor-id='6d342e5f-1b4e-4677-8665-899be6b548a5'></body>",  
            });
            Navigate(`/document/${res.data}`);
        } catch (e) {
            toast.error('Failed to create new project');
            console.error('Failed to create new project:', e);
        }
    }

    const handleDelete = async (item) => {
        try {
            await NewProjectService.delete(item.id);
            setProjectList(projectList.filter(i => i.id !== item.id));
            setShowMenu(false);
            toast.success('Project deleted successfully!');
            getAllProjects();
            
            // 如果刪除的是當前正在查看的文檔，重新獲取項目詳情
            if (item.id === parseInt(id)) {
                getProjectDetails();
            }
        } catch (e) {
            toast.error('Failed to delete project. Please try again later.');
            console.error('Failed to delete project:', e);
        }
    };

    const handleMoreClick = (e, item) => {
        e.stopPropagation();
        const rect = e.currentTarget.getBoundingClientRect();
        setMenuPosition({ x: rect.right, y: rect.top });
        setActiveItem(item.id);
        setShowMenu(true);
    };

    useEffect(() => {
        const handleClickOutside = () => {
            setShowMenu(false);
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    return (
        <div className={`py-[24px] ps-[8px] bg-[#F9FAFB] h-screen relative`}>
            <div className="mx-[6px] mb-[8px]">
                <img src={logo} alt="Logo" />
            </div>
            <div className="relative">
                <button 
                    onClick={scrollUp}
                    className="absolute right-0 top-0 z-10 w-[18px] h-[18px] flex items-center justify-center bg-[#F9FAFB] transition-colors"
                >
                    <div className={style.upTriangle}></div>
                </button>
                <CustomScrollbar>
                    <div className={`py-10`}>
                        <p className='fixed bg-[#F9FAFB] pb-[8px] z-2'>Project List</p>
                        <ul className='mt-[44px] relative'>
                            {projectList.map((item) => (
                                <li 
                                    key={item.id} 
                                    className={`cursor-pointer group flex items-center justify-between px-[6px] py-[10px] hover:bg-gray-200 rounded ${
                                        item.id === currentId ? 'bg-gray-200' : ''
                                    }`}
                                    onClick={() => Navigate(`/document/${item.id}`)}
                                >
                                    <span>{item?.title}</span>
                                    <button 
                                        className="opacity-0 group-hover:opacity-100 transition-opacity"
                                        onClick={(e) => handleMoreClick(e, item)}
                                    >
                                        <MoreHorizontal size={16} />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </CustomScrollbar>
                <button 
                    onClick={scrollDown}
                    className="absolute right-0 bottom-0 z-10 w-[18px] h-[18px] flex items-center justify-center bg-[#F9FAFB] transition-colors"
                >
                    <div className={style.downTriangle}></div>
                </button>
            </div>
            <button type="button" className='absolute bottom-4 end-6'>
                <img src={addBtn} alt="create new project" onClick={createNewProject}></img>
            </button>

            {/* Context Menu */}
            {showMenu && (
                <div 
                    className="absolute bg-white shadow-lg rounded-md py-1 z-50"
                    style={{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px` }}
                >
                    <button 
                        className="w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100 flex items-center"
                        onClick={() => handleDelete(projectList.find(item => item.id === activeItem))}
                    >
                        Delete
                        <Trash2 size={16} className="ml-2" />
                    </button>
                </div>
            )}
        </div>
    );
}