import { useState, useRef } from 'react';
import styled from 'styled-components';

import SideBar from './sideBar';
import HorizontalBar from './horizontalBar';
import CustomTable from './customTable';
import { withTranslation } from '../utils/i18n';

import sharedStyle from "./shared.module.scss";
import style from "./file.module.scss";

const Help = ({ t }) => {
    return (
      <div className='w-full grid grid-cols-4 lg:grid-cols-12 gap-x-[40px]'>
        <div className='hidden lg:block lg:col-span-2'>
          <SideBar />
        </div>
        <div className='col-span-4 lg:col-span-10 lg:col-start-3'>
          <HorizontalBar />
          <div className='pt-[110px] px-[20px] w-[70%]'>
            <div>
                <h3 className={`${sharedStyle.title}`}>{t('help.about.label')}</h3>
                <textarea className={`${sharedStyle.textarea} w-full`} rows={10}></textarea>
            </div>
            <div>
                <h3 className={`${sharedStyle.title}`}>{t('help.tutorial.label')}</h3>
                <textarea className={`${sharedStyle.textarea} w-full`} rows={10}></textarea>
            </div>
          </div>
        </div>
      </div>
    )
}

export default withTranslation(Help);