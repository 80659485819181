import { useState, useRef } from 'react';
import styled from 'styled-components';

import SideBar from './sideBar';
import HorizontalBar from './horizontalBar';
import CustomTable from './customTable';

import upload from "../assets/images/file/upload.png";

import sharedStyle from "./shared.module.scss";
import style from "./file.module.scss";

const Button = styled.button`
    color: white;
    background: #07BBD3;
    padding: 10px 20px;
    width: fit-content;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const tableHeaders = ["No.", "Name", "Size", "Creation date"];
const tableData = [
    ["1", "18682.pdf", "150MB", "2024/05/06"],
    ["2", "18682.pdf", "350MB", "2024/07/06"],
    ["3", "18682.pdf", "600MB", "2024/08/06"],
    ["4", "18682.pdf", "780MB", "2024/09/06"],
];
const tableColumnWidths = ["10%", "40%", "20%", "30%"];

const CustomInputTypeFile = ({ onFileSelect }) => {
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState('');

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      if (onFileSelect) {
        onFileSelect(file);
      }
    }
  };

  return (
    <div className="custom-file-upload mt-[10px]">
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept=".pdf,.csv,.html,.txt"
        style={{ display: 'none' }}
      />
      <Button onClick={handleButtonClick}>Upload</Button>
      <style jsx>{`
        .custom-file-upload {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      `}</style>
    </div>
  );
};

export default function File() {
    const handleFileSelect = (file) => {
      console.log('Selected file:', file);
      // 在這裡處理選擇的文件
    };

    return (
      <div className='w-full grid grid-cols-4 lg:grid-cols-12 gap-x-[40px]'>
        <div className='hidden lg:block lg:col-span-2'>
          <SideBar />
        </div>
        <div className='col-span-4 lg:col-span-10 lg:col-start-3'>
          <HorizontalBar />
          <div className='pt-[110px] px-[20px] flex'>
            <div className='me-[36px]'>
                <h3 className={`${sharedStyle.title}`}>Upload File</h3>
                <div className={`${style.uploadFileContainer} px-[8px]`}>
                    <img src={upload} alt="upload" className='mx-auto mt-[89px]'></img>
                    <div className='text-[#69747F] mt-[40px] mb-[28px]'>
                        <p>● Supported file types: .pdf, .csv, .html, .txt</p>
                        <p className='mt-[16px]'>● Maximum File Size: 1000MB</p>
                    </div>
                </div>
                {/* <Button className='mt-[10px]'>Upload</Button> */}
                <CustomInputTypeFile onFileSelect={handleFileSelect} />
            </div>
            <div className='min-w-[45%] xl3:min-w-[35%]'>
                <h3 className={`${sharedStyle.title}`}>File List</h3>
                <CustomTable headers={tableHeaders} data={tableData} columnWidths={tableColumnWidths} />
                <Button className='mt-[10px]'>Download all files</Button>
            </div>
          </div>
        </div>
      </div>
    )
}