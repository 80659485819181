// import { useEffect, useState, useCallback, useRef } from 'react';
// import { useParams, useLocation, useNavigate } from 'react-router-dom';
// import { html } from '@yoopta/exports';

// // import SideBar from './sideBar';
// import SideBar from './sideBar_TEST';
// import HorizontalBar from './horizontalBar';
// import FilterComponent from './filterComponent';
// import AIEditorComponent from './aiEditorComponent_TEST';
// import { sdk } from './corsSessions';

// import NewProjectService from '../service/project-service';

// export default function Document() {
//     const [question, setQuestion] = useState('');
//     const [filteredData, setFilteredData] = useState(null);
//     const [visualizationData, setVisualizationData] = useState(null);
//     const [content, setContent] = useState('');
//     const [title, setTitle] = useState('');
//     const { id } = useParams();
//     const location = useLocation();
//     const navigate = useNavigate();
//     const editorRef = useRef(null);
//     const isSavingRef = useRef(false);
//     const pendingNavigationRef = useRef(null);

//     const handleChatBotQuestion = (newQuestion) => {
//         setQuestion(newQuestion);
//     };

//     const handleVisualizationData = (data) => {
//         setVisualizationData(data);
//     };
    
//     const getProjectDetails = useCallback(async () => {
//       try {
//           const res = await NewProjectService.getDetails(id);
//           const { title } = res.data;
//           setTitle(title);
//       } catch (e) {
//           console.log(e);
//       }
//     }, [id]);

//     // Save function that returns a promise
//     const saveToDatabase = useCallback(async () => {
//         if (!editorRef.current || isSavingRef.current) return;
        
//         isSavingRef.current = true;
//         try {
//             const editor = editorRef.current.getEditor();
//             if (!editor) return;

//             const currentContent = editor.getEditorValue();
//             if (!currentContent) return;

//             let serializedTexts = html.serialize(editor, currentContent);
//             serializedTexts = serializedTexts.replace(/"/g, "'");

//             // if (id === "new") {
//             //     const res = await NewProjectService.create({
//             //         title: title || "(No title)", 
//             //         content: serializedTexts
//             //     });
//             //     // 更新 URL，但不觸發新的保存
//             //     window.history.replaceState(null, '', `#/document/${res.data}`);
//             // } else {
//                 await NewProjectService.update(id, {
//                     title: title || "(No title)", 
//                     content: serializedTexts
//                 });
//             // }
//         } catch (error) {
//             console.error('Failed to save:', error);
//         } finally {
//             isSavingRef.current = false;
//         }
//     }, [id, title]);

//     // 處理頁面卸載
//     useEffect(() => {
//         const handleBeforeUnload = (e) => {
//             if (!isSavingRef.current) {
//                 e.preventDefault();
//                 e.returnValue = '';
                
//                 saveToDatabase().then(() => {
//                     window.removeEventListener('beforeunload', handleBeforeUnload);
//                 });
//             }
//         };

//         window.addEventListener('beforeunload', handleBeforeUnload);
//         return () => {
//             window.removeEventListener('beforeunload', handleBeforeUnload);
//         };
//     }, [saveToDatabase]);

//     // 監聽位置變更
//     useEffect(() => {
//         return () => {
//             // 組件卸載時保存
//             if (!isSavingRef.current) {
//                 saveToDatabase();
//             }
//         };
//     }, [location, saveToDatabase]);

//     useEffect(() => {
//         getProjectDetails();
//     }, [getProjectDetails]);

//     return (
//         <div className='w-full grid grid-cols-4 lg:grid-cols-12 gap-x-[40px]'>
//             <div className='hidden lg:block lg:col-span-2'>
//                 <SideBar content={content} />
//             </div>
//             <div className='col-span-4 lg:col-span-10 lg:col-start-3'>
//                 <HorizontalBar />
//                 <FilterComponent 
//                     question={question}
//                     data={visualizationData}
//                     onFilteredData={setFilteredData}
//                 />
//                 <div className="md:pt-[20px] md:pb-[40px] md:pl-[100px] md:pr-[80px] px-[20px] pt-[80px] pb-[40px]">
//                     <input 
//                         className='focus:outline-none block w-full font-bold border-b py-1' 
//                         placeholder="Title" 
//                         onChange={(e) => setTitle(e.target.value)} 
//                         value={title} 
//                         style={{ fontSize: "2.25rem", lineHeight: "1.5rem" }} 
//                     />
//                     <AIEditorComponent 
//                         ref={editorRef}
//                         sdk={sdk} 
//                         setQuestion={handleChatBotQuestion}
//                         content={content}
//                         setContent={setContent}
//                         title={title}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// }

import { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { html } from '@yoopta/exports';

import SideBar from './sideBar_TEST';
import HorizontalBar from './horizontalBar';
import FilterComponent from './filterComponent';
import AIEditorComponent from './aiEditorComponent_TEST';
import { sdk } from './corsSessions';

import NewProjectService from '../service/project-service';

export default function Document() {
    const [question, setQuestion] = useState('');
    const [filteredData, setFilteredData] = useState(null);
    const [visualizationData, setVisualizationData] = useState(null);
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [projectList, setProjectList] = useState([]);
    const { id } = useParams();
    const editorRef = useRef(null);
    const isSavingRef = useRef(false);
    const Navigate = useNavigate();
    const Location = useLocation();
    const pendingSaveRef = useRef(false);
    const maxIdRef = useRef(null);

    // 獲取所有項目列表
    const getAllProjects = useCallback(async () => {
        try {
            const res = await NewProjectService.search();
            setProjectList(res.data.data);
        } catch (e) {
            console.error("Failed to fetch projects:", e);
        }
    }, []);

      // 處理標題更改
      const handleTitleChange = useCallback(async (newTitle) => {
        setTitle(newTitle); // 更新本地狀態

        if (!id || (!/^\d+$/.test(id))) return;

        try {
            await NewProjectService.update(id, {
                title: newTitle || "(No title)",
                content: content // 保持現有內容
            });
            getAllProjects(); // 更新側邊欄列表
        } catch (error) {
            console.error('Failed to update title:', error);
        }
    }, [id, content, getAllProjects]);

    // // 獲取當前項目詳情
    // const getProjectDetails = useCallback(async () => {
    //     console.log(maxIdRef);

    //     if (!id || (!/^\d+$/.test(id))) return;
        
    //     try {
    //         const res = await NewProjectService.getDetails(id);
    //         const { title, content } = res.data;
    //         setTitle(title);
    //         setContent(content);
    //     } catch (e) {
    //         console.error("Failed to fetch project details:", e);
    //         // if (e.response && e.response.data && e.response.data.errorCode && e.response.data.errorCode === "2000") {
    //         //   Navigate(-1);
    //         // }
    //     }
    // }, [id]);

    // 獲取當前項目詳情
    const getProjectDetails = useCallback(async () => {
        if (!id || (!/^\d+$/.test(id))) return;
        
        try {
            const res = await NewProjectService.getDetails(id);
            const { title, content } = res.data;
            setTitle(title);
            setContent(content);
        } catch (e) {
            console.error("Failed to fetch project details:", e);
            
            if (e.response && e.response.data && e.response.data.errorCode === "2000") {
                try {
                    // 重新獲取專案列表
                    const response = await NewProjectService.search();
                    const projects = response.data.data || [];
                    
                    if (projects.length > 0) {
                        // 獲取所有 ID 並排序
                        const sortedIds = projects
                            .map(project => project.id)
                            .sort((a, b) => b - a); // 降序排序
                        
                        // 找到適當的導航目標
                        let targetId;
                        if (sortedIds[0] === parseInt(id)) {
                            // 如果刪除的是最大 ID，導航到第二大 ID
                            targetId = sortedIds[1];
                        } else {
                            // 否則導航到最大 ID
                            targetId = sortedIds[0];
                        }
                        
                        if (targetId) {
                            maxIdRef.current = targetId;
                            Navigate(`/document/${targetId}`);
                        } else {
                            Navigate('/document/new');
                        }
                    } else {
                        Navigate('/document/new');
                    }
                } catch (searchError) {
                    console.error("Failed to fetch project list:", searchError);
                    Navigate('/document/new');
                }
            }
        }
    }, [id, Navigate]);
    
    // 保存到數據庫
    const saveToDatabase = useCallback(async () => {
        if (!id || (!/^\d+$/.test(id))) return;
        
        const titleNode = document.querySelector("#document-title");
        console.log(title)

        console.log(editorRef.current, isSavingRef.current);

        if (!editorRef.current || isSavingRef.current) return;

        isSavingRef.current = true;
        try {
            const editor = editorRef.current.getEditor();
            console.log(editor);
            if (!editor) return;

            const currentContent = editor.getEditorValue();
            console.log(currentContent);
            if (!currentContent) return;

            let serializedTexts = html.serialize(editor, currentContent);
            serializedTexts = serializedTexts.replace(/"/g, "'");
            console.log(serializedTexts);

            await NewProjectService.update(id, {
                title:  titleNode ? titleNode.value : "(No title)", 
                content: serializedTexts
            });
            console.log("finished updated");

            getAllProjects(); // 更新側邊欄列表
        } catch (error) {
            console.error('Failed to save:', error);
        } finally {
            isSavingRef.current = false;
        }
    }, [id, title, getAllProjects]);

    // 處理頁面卸載
    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (!isSavingRef.current) {
                e.preventDefault();
                e.returnValue = '';
                
                saveToDatabase().then(() => {
                    window.removeEventListener('beforeunload', handleBeforeUnload);
                });
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            if (!isSavingRef.current) {
                saveToDatabase();
            }
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [saveToDatabase]);

    // // 初始加載數據
    // useEffect(() => {
    //     getAllProjects();
    //     getProjectDetails();
    // }, [getAllProjects, getProjectDetails, id]);

    useEffect(() => {
        const fetchData = async () => {
            try {
              if (Location.pathname.startsWith('/document')) {  
                  // 1. 執行 getAllProjects 並找出最大 ID
                  const response = await NewProjectService.search();
                  const projects = response.data.data || [];  // 確保即使 API 失敗也有一個空數組
                  
                  setProjectList(projects);  // 更新專案列表狀態
                  
                  // 如果有專案，找出最大 ID
                  if (projects.length > 0) {
                      const maxId = Math.max(...projects.map(project => project.id));
                      maxIdRef.current = maxId;
                  } else {
                      maxIdRef.current = null;  // 如果沒有專案，設置為 null
                  }

                  // 2. 檢查當前 URL ID
                  const isValidId = id && /^\d+$/.test(id);

                  if (!isValidId) {
                      // 如果 ID 無效且有最大 ID，導向到最大 ID 的頁面
                      if (maxIdRef.current) {
                          Navigate(`/document/${maxIdRef.current}`);
                      } else {
                          // 如果沒有最大 ID（可能是空專案列表），可以導向到新建專案頁面或其他頁面
                          Navigate('/document/new');  // 或其他適當的處理方式
                      }
                      return;
                  }

                  // 3. 如果 ID 有效，執行 getProjectDetails
                  await getProjectDetails();
              }
            } catch (error) {
                console.error('Error fetching data:', error);
                // 錯誤處理
                if (Location.pathname.startsWith('/document')) {
                  if (maxIdRef.current) {
                      Navigate(`/document/${maxIdRef.current}`);
                  } else {
                      Navigate('/document/new');
                  }
              }
            }
        };

        fetchData();
    }, [Navigate, id, getProjectDetails]);

    return (
        <div className='w-full h-full grid grid-cols-4 lg:grid-cols-12 gap-x-[40px]'>
            <div className='hidden lg:block lg:col-span-2'>
                <SideBar 
                    projectList={projectList}
                    currentId={id}
                    onProjectDeleted={getAllProjects}
                    setProjectList={setProjectList}
                    getAllProjects={getAllProjects}
                    getProjectDetails={getProjectDetails}
                />
            </div>
            <div className='col-span-4 lg:col-span-10 lg:col-start-3'>
                <HorizontalBar />
                <div className='mt-[60px]'>
                  <FilterComponent 
                      question={question}
                      data={visualizationData}
                      onFilteredData={setFilteredData}
                  />
                  {(/^\d+$/.test(id)) && (
                      <div className="md:pt-[20px] md:pb-[40px] md:pl-[100px] md:pr-[80px] px-[20px] pt-[80px] pb-[40px] relative">
                          <input 
                              className='focus:outline-none block w-full font-bold border-b py-1' 
                              placeholder="Title" 
                              onChange={(e) => handleTitleChange(e.target.value)}
                              value={title} 
                              style={{ fontSize: "2.25rem", lineHeight: "1.5rem" }}
                              id="document-title"
                          />
                          <AIEditorComponent 
                              ref={editorRef}
                              sdk={sdk} 
                              content={content}
                              setContent={setContent}
                              title={title}
                              onSave={saveToDatabase}
                          />
                      </div>
                  )}
                </div>
            </div>
        </div>
    );
}